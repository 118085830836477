import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const error = (message: string) => {
  toast.error(message);
};

const success = (message: string) => {
  toast.success(message);
};

const warning = (message: string) => {
  toast.warn(message);
};

export const Toast = {
  error,
  success,
  warning,
};