//exception
import { ApiException } from '../../api/ApiException';

//appAxios
import appAxios from '../../api/appAxios';

//interfaces
import { IBankSlipStatus } from '../../interfaces/IBankSlipStatus';
import { IGenericFileInfo, IIncomeReport } from '../../interfaces/IIncomeReport';
import { IInstallmentsExtractInfo } from '../../interfaces/IInstallmentsExtractInfo';
import { IUpdateReceivedPaymentSlipRequest } from '../../interfaces/IUpdateReceivedPaymentSlipRequest';


const getAllStatus = async (): Promise<IBankSlipStatus[] | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_STATUS}`);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const getDownload = async (
  contractCode: string, number: string, originCode: string, dueDate: string, status: string,
): Promise<any | ApiException> => {
  try {
    const installments = number === '0' ? '' : number;
    const response = await appAxios
      .get(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_DOWNLOAD
        ?.replace('{contractCode}', contractCode)
        ?.replace('{originCode}', originCode)}`, {
        params: {
          installments,
          dueDate,
          status,
        },
      });
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const sendEmail = async (
  contractCode: string, number: string, originCode: string, dueDate: string, status: string,
): Promise<any | ApiException> => {
  try {
    const installments = number === '0' ? '' : number;
    const response = await appAxios
      .post(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_SEND_EMAIL
        ?.replace('{contractCode}', contractCode)
        ?.replace('{originCode}', originCode)}`, {
        params: {
          installments,
          dueDate,
          status,
        },
      });
    if (response?.status === 200) return;
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const getDownloadIncomeReport = async (contractCode: string, year: string, originCode: string): Promise<IIncomeReport | ApiException> => {
  try {
    const response = await appAxios
      .get(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_INCOME_REPORT_DOWNLOAD
        ?.replace('{contractCode}', contractCode)
        ?.replace('{year}', year)
        ?.replace('{originCode}', originCode)}`);
    if (response.status === 200 && response?.data === '') return new ApiException('Não tem informe de rendimento para esse ano');
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const getAllYearsIncomeReport = async (contractCode: string, originCode: string): Promise<number[] | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_INCOME_REPORT_YEARS_APTABLE
      ?.replace('{contractCode}', contractCode)
      ?.replace('{originCode}', originCode)}`);
    if (response.status === 200 && response?.data === '') return [];

    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const sendEmailIncomeReport = async (contractCode: string, year: string, originCode: string): Promise<void | ApiException> => {
  try {
    const response = await appAxios
      .post(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_INCOME_REPORT_SEND_EMAIL
        ?.replace('{contractCode}', contractCode)
        ?.replace('{year}', year)
        ?.replace('{originCode}', originCode)}`);
    if (response?.status === 200) return;
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const getInstallmentsExtract = async (contractCode: string, originCode: string): Promise<IInstallmentsExtractInfo | ApiException | []> => {
  try {
    const response = await appAxios
      .get(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_INSTALLMENT_EXTRACT
        ?.replace('{contractCode}', contractCode)
        ?.replace('{originCode}', originCode)}`);
    if (response.status === 200 && response?.data === '') return [];
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const getDownloadInstallmentsExtract = async (contractCode: string, originCode: string, beginNumber: string, endNumber: string, status: string | null): Promise<IGenericFileInfo | ApiException> => {
  try {
    const response = await appAxios
      .get(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_INSTALLMENT_EXTRACT_DOWNLOAD
        ?.replace('{contractCode}', contractCode)
        ?.replace('{originCode}', originCode)}`, {
        params: {
          beginNumber,
          endNumber,
          status,
        },
      });
    if (response.status === 200 && response?.data === '') return new ApiException('Não tem extrato de parcela');
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const sendEmailInstallmentsExtract = async (contractCode: string, originCode: string, beginNumber: string, endNumber: string, status: string | null): Promise<void | ApiException> => {
  try {
    const response = await appAxios
      .post(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_INSTALLMENT_EXTRACT_SEND_EMAIL
        ?.replace('{contractCode}', contractCode)
        ?.replace('{originCode}', originCode)}`, null, {
        params: {
          beginNumber,
          endNumber,
          status,
        },
      });
    if (response?.status === 200) return;
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};
const toUpdateReceivedPaymentSlip = async (request: IUpdateReceivedPaymentSlipRequest): Promise<void | ApiException> => {
  try {
    const response = await appAxios
      .post(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_RECEIVED_TO_UPDATE}`,
        {
          contractCode: request.contractCode,
          originCode: request.originCode,
          receivedPaymentSlip: request.receivedPaymentSlip,
        },
      );
    if (response?.status === 200) return;
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

export const BankSlipService = {
  getAllStatus,
  getDownload,
  sendEmail,
  getDownloadIncomeReport,
  getAllYearsIncomeReport,
  sendEmailIncomeReport,
  getInstallmentsExtract,
  getDownloadInstallmentsExtract,
  sendEmailInstallmentsExtract,
  toUpdateReceivedPaymentSlip,
};