// react
import { useState } from 'react';

type Props = {
  tabsName: string[],
  tabsInfo: JSX.Element[]
  numberPage?: number | null
}
const Tab: React.FC<Props> = ({ tabsName, tabsInfo, numberPage = null }) => {
  const [toggleState, setToggleState] = useState<number>((numberPage ? numberPage : 1));

  const toggleTab = (index: number) => {
    setToggleState(index);
  };

  return (
    <div className="division-tab-container">
      <div className="bloc-tabs">
        {tabsName && tabsName.map((name, index) => {
          return <div
            key={index + 1}
            className={`btn-tabs ${toggleState === (index + 1) ? 'tabs active-tabs' : 'tabs'}`}
            onClick={() => toggleTab((index + 1))}>
            {name}
          </div>;
        })}
      </div>

      <div className="content-tabs">
        {tabsInfo && tabsInfo.map((info, index) => {
          return <div key={index + 1}>
            {toggleState === (index + 1) && (
              <div className={toggleState === (index + 1) ? 'content  active-content' : 'content'}>
                {info}
              </div>
            )}
          </div>;
        })}
      </div>
    </div>
  );
};

export default Tab;