//react router dom
import { BrowserRouter } from 'react-router-dom';

import { useContext } from 'react';

// context
import { AuthContext } from './hooks/HookUseContext';
import { ProfileUseContext } from './hooks/ProfileUseContext';

//routers
import LoggedOutAuth from './routes/LoggedOutAuth';
import LoggedAuth from './routes/LoggedAuth';

//components
import Header from './components/header/Header';
import Footer from './components/footer/Footer';


function App() {

  const { authValue } = useContext(AuthContext);
  return (
    <BrowserRouter>
      {!authValue && <>
        <LoggedOutAuth />
        <Footer fixed="fixed" />
      </>
      }
      {authValue && (
        <div className="containerMain">
          <ProfileUseContext>
            <Header />
            <LoggedAuth />
            <Footer />
          </ProfileUseContext>
        </div>)
      }
    </BrowserRouter>
  );
}

export default App;
