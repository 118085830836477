//react
import { useEffect } from 'react';

//react table
// css
import styles from './PaymentsSlipTable.module.css';

//icons
import { AiOutlineDownload, AiOutlineEye, AiOutlineMail } from 'react-icons/ai';

//moment
import moment from 'moment';

//util
import { PossiblesStatus } from '../../../utils/PossiblesStatus';

//tooltip
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { IContent } from '../../../interfaces/IPage';
import { Formatters } from '../../../utils/Formatters';

type Props = {
  data: IContent[]
  downloadDocument(data: IContent): void,
  sendDocumentEmail(data: IContent): void,
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  selectedAll: boolean;
  setSelectedAll: React.Dispatch<React.SetStateAction<boolean>>;
}
const PaymentsSlipTable: React.FC<Props> = ({
                                              data,
                                              downloadDocument,
                                              sendDocumentEmail,
                                              selected,
                                              setSelected,
                                              selectedAll,
                                              setSelectedAll,
                                            }) => {
  const toggleSelectedAll = () => {
    setSelectedAll(!selectedAll);
    if (!selectedAll) {
      const unifiedDistinctArray = Array.from(new Set([...selected, ...data.map(dataValue => dataValue.number)]));
      setSelected(unifiedDistinctArray);
    } else {
      setSelected([]);
    }
  };

  const toggleSelected = (id: number) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(item => item !== id));
      setSelectedAll(false);
    } else {
      setSelected([...selected, id]);
    }
  };

  const hasSelected = (id: number) => {
    return selected.includes(id);
  };

  useEffect(() => {
    if (selectedAll) {
      const unifiedDistinctArray = Array.from(new Set([...selected, ...data.map(dataValue => dataValue.number)]));
      setSelected(unifiedDistinctArray);
    }
  }, [data]);

  const messageTooltip = (dataValue: IContent): string => {
    if (dataValue?.status.toLocaleLowerCase() === 'paid_out') return 'Boleto Pago';
    if (dataValue?.status.toLocaleLowerCase() === 'open') return 'Boleto indisponível. Entre em contato com o nosso atendimento.';
    return '';
  };

  return (
    <table className={styles.containerTable}>
      <thead>
      <tr>
        <th className={styles.checkbox}>
          <Tooltip id="tooltip-selected-all" />
          <input
            type="checkbox"
            checked={selectedAll}
            onChange={() => toggleSelectedAll()}
            data-tooltip-id="tooltip-selected-all"
            data-tooltip-content="Download de todos os boletos selecionados"
          />
        </th>
        <th className={styles.headerColumn}>Status</th>
        <th className={styles.headerColumn}>Vencimento</th>
        <th className={styles.headerColumn}>Parcela atual</th>
        <th className={styles.headerColumn}>Valor do documento</th>
        <th className={styles.headerColumn}>Valor pago</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {data && data.map((dataValue: IContent, index) => (
        <tr key={index}>
          <td className={styles.checkbox}>
            <Tooltip id="tooltip-selected" />
            <input
              type="checkbox"
              checked={dataValue.visible && hasSelected(dataValue.number)}
              onChange={() => toggleSelected(dataValue.number)}
              disabled={!dataValue.visible}
              data-tooltip-id="tooltip-selected"
              data-tooltip-content={dataValue.visible ? 'Download' : dataValue?.advisoryContactMessage}
            />
          </td>
          <td>
            <span className={styles.titleTable}>{'Status:'}</span>
            <div className={styles.status}>
              <div className={styles[dataValue?.status?.toLocaleLowerCase()]}>
                {PossiblesStatus.statusPayments(dataValue?.status?.toLocaleLowerCase())}
              </div>
              {dataValue?.status.toLocaleLowerCase() === 'paid_out' && dataValue?.paymentDate ? (
                <>
                  <AiOutlineEye data-tooltip-id="my-tooltip"
                                data-tooltip-content={moment(dataValue.paymentDate).format('DD/MM/yyyy')} />
                  <Tooltip id="my-tooltip" />
                </>
              ) : (
                <>
                  <AiOutlineEye style={{ opacity: '0.5', cursor: 'not-allowed' }} />
                </>
              )}
            </div>
          </td>
          <td>
            <span className={styles.titleTable}>{'Vencimento:'}</span>
            {dataValue.dueDate ? moment(dataValue.dueDate).format('DD/MM/yyyy') : ''}
          </td>
          <td>
            <span className={styles.titleTable}>{'Parcela atual:'}</span>
            {`${dataValue?.number}/${dataValue?.totalNumber}`}
          </td>
          <td>
            <span className={styles.titleTable}>{'Valor do documento:'}</span>
            {Formatters.formatMoneyInRealBrazil(dataValue?.installmentValue)}
          </td>
          <td>
            <span className={styles.titleTable}>{'Valor Pago:'}</span>
            {dataValue?.status.toLocaleLowerCase() === 'paid_out' && dataValue?.paidValue >= 0 && (
              <>
                {Formatters.formatMoneyInRealBrazil(dataValue?.paidValue)}
              </>
            )}
          </td>
          <td>
            <div className={styles.actions}>
              {(dataValue?.status.toLocaleLowerCase() === 'open' || dataValue?.status.toLocaleLowerCase() === 'late') && dataValue.visible && (
                <>
                  <Tooltip id="my-tooltip-2" />
                  <AiOutlineDownload onClick={() => downloadDocument(dataValue)}
                                     data-tooltip-id="my-tooltip-2" data-tooltip-content="Download" />
                  <AiOutlineMail onClick={() => sendDocumentEmail(dataValue)}
                                 data-tooltip-id="my-tooltip-2"
                                 data-tooltip-content="Receber no e-mail" />
                </>)}
              {dataValue?.status.toLocaleLowerCase() === 'late' && !dataValue.visible && (
                <>
                  <Tooltip id="my-tooltip-2" />
                  {dataValue?.contactLink ? (
                    <>
                      <a href={dataValue?.contactLink} target={`_blank`}>
                        <AiOutlineDownload className={` ${styles.disabledLink}`}
                                           data-tooltip-id="my-tooltip-2" data-html="true"
                                           data-tooltip-content={dataValue?.advisoryContactMessage} />
                      </a>
                      <a href={dataValue?.contactLink} target={`_blank`}>
                        <AiOutlineMail className={` ${styles.disabledLink}`}
                                       data-tooltip-id="my-tooltip-2"
                                       data-tooltip-content={dataValue?.advisoryContactMessage} />
                      </a>
                    </>
                  ) : (
                    <>
                      <AiOutlineDownload className={`${styles.disabled} `}
                                         data-tooltip-id="my-tooltip-2" data-html="true"
                                         data-tooltip-content={dataValue?.advisoryContactMessage} />
                      <AiOutlineMail className={`${styles.disabled} `}
                                     data-tooltip-id="my-tooltip-2"
                                     data-tooltip-content={dataValue?.advisoryContactMessage} />
                    </>
                  )}

                </>)}
              {dataValue?.status.toLocaleLowerCase() === 'paid_out' && !dataValue.visible && (
                <>
                  <Tooltip id="my-tooltip-2" />
                  <AiOutlineDownload className={styles.disabled} data-tooltip-id="my-tooltip-2"
                                     data-html="true"
                                     data-tooltip-content={messageTooltip(dataValue)} />
                  <AiOutlineMail className={styles.disabled} data-tooltip-id="my-tooltip-2"
                                 data-tooltip-content={messageTooltip(dataValue)} />
                </>)}
            </div>
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  );
};

export default PaymentsSlipTable;