const statusContract = (status: string): string => {
  if (status === 'IN_PROGRESS') return 'Andamento';
  if (status === 'CLOSED') return 'Encerrado';

  return '';
};

const statusPayments = (status: string): string => {
  if (status === 'open') return 'Em aberto';
  if (status === 'paid_out') return 'Pago';
  if (status === 'late') return 'Vencido';
  return '';
};

export const PossiblesStatus = {
  statusContract,
  statusPayments,
};