const formatterCpfCnpj = (value: string): string => {
  let x: any
    = value?.replace(/\D/g, '')?.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
  if (x) {
    value = !x[2] ? x[1] : x[1] + '.' + x[2] + (x[3] ? '.' : '') + x[3] + (x[4] ? '/' : x[4]) + x[4] + (x[5] ? '-' + x[5] : '');
  }

  if (value && value.length < 15) {
    x = value?.replace(/\D/g, '')?.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
    value = !x[2] ? x[1] : x[1] + '.' + x[2] + (x[3] ? '.' : '') + x[3] + (x[4] ? '-' + x[4] : '');
  }

  return value;
};

const phoneMask = (phone: string): string => {
  phone = phone?.replace(/\D/g, '');
  phone = phone?.replace(/^(\d)/, '+$1');
  phone = phone?.replace(/(.{3})(\d)/, '$1($2');
  phone = phone?.replace(/(.{6})(\d)/, '$1)$2');
  if (phone?.length === 12) {
    phone = phone?.replace(/(.{1})$/, '-$1');
  } else if (phone?.length === 13) {
    phone = phone?.replace(/(.{2})$/, '-$1');
  } else if (phone?.length === 14) {
    phone = phone?.replace(/(.{3})$/, '-$1');
  } else if (phone?.length === 15) {
    phone = phone?.replace(/(.{4})$/, '-$1');
  } else if (phone?.length > 15) {
    phone = phone?.replace(/(.{4})$/, '-$1');
  }
  phone = phone?.replace('+55', '55 ');
  phone = phone?.replace(')', ') ');
  return phone;
};

const phoneMaskSimple = (value: string) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

const zipCodeMask = (value: string) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{5})(\d)/, '$1-$2');
  return value;
};

const formatMoneyInRealBrazil = (value: number): string => {
  if (!value) return '';

  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

const numberToArray = (num: number): number[] => {
  return Array.from({ length: num }, (_, i) => i + 1);
};

export const Formatters = {
  formatterCpfCnpj,
  phoneMask,
  phoneMaskSimple,
  zipCodeMask,
  formatMoneyInRealBrazil,
  numberToArray,
};