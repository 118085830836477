//react
import React, { useState } from 'react';

//css
import styles from './Login.module.css';

//interfaces
import { IRecaptchaGoogleResponse } from '../../interfaces/IRecaptchaGoogleResponse';

//service
import { RecaptchaGoogleService } from '../../services/recaptcha_google/RecaptchaGoogleService';

//exception
import { ApiException } from '../../api/ApiException';

type Props = {
  handleSubmitLogin(e: React.FormEvent, captcha: any): void
  loading: boolean,
  username: string
  setUsername(username: string): void
  Formatters: any,
  password: string,
  setPassword(password: string): void,
  handlePasswordRecovery(e: React.FormEvent): void,
  message: string,
  Message: any,
  handleFisrtAccess(e: React.FormEvent): void,
  handlePasswordFormScreen(e: React.FormEvent): void,
  attemps: number,
  setLoading(loading: boolean): void,
  seePassword(id: string): string
  setMessage(message: string): void,
  Logo: string,
  SmallLogo: string

}
const SeePresentationScreen: React.FC<Props> = ({
                                                  handleSubmitLogin,
                                                  loading,
                                                  username,
                                                  password,
                                                  message,
                                                  Message,
                                                  handleFisrtAccess,
                                                  handlePasswordFormScreen,
                                                  attemps,
                                                  setLoading,
                                                  Logo,
                                                }) => {
  const key_site = process.env.REACT_APP_RECAPTCHA_KEY_SITE;
  const key_secret = process.env.REACT_APP_RECAPTCHA_KEY_SECRET;
  const [captchaValue, setCaptchaValue] = useState<any>();
  let captcha: any;
  return (
    <form className={styles.form_presentation} onSubmit={(e: React.FormEvent) => {
      e.preventDefault();
      if (!username || !password) {
        return;
      }
      setLoading(true);
      if (attemps > 1 && key_site && key_secret) {
        const dados = {
          response: captchaValue,
        };
        RecaptchaGoogleService.sendTokenRecaptcha(dados).then((response) => {
          if (response instanceof ApiException) {
            captcha?.reset();
            setCaptchaValue(null);
            setLoading(false);
          } else {
            const recaptchaGoogleResponse: IRecaptchaGoogleResponse = response;
            if (recaptchaGoogleResponse.success) {
              handleSubmitLogin(e, captcha);
              setCaptchaValue(null);
            }
          }
        });
      } else {
        handleSubmitLogin(e, null);
      }
    }}>
      {message && <Message message={message} type="error_login" />}
      {attemps > 1 &&
        <p className={styles.central_message}>Em caso de duvida, você pode ligar para a central do banco BBC
          Digital através do número <span className={styles.central_message_span}>0800 771 4080</span></p>}

      <img className={styles.logoImg} src={Logo} alt="logo BBC" />
      <div className={styles.confirm_button}>
        {attemps > 1 && key_site && key_secret ? (
          <>
            {loading && <button disabled>Aguarde...</button>}
            {captchaValue && !loading && <button>Entrar 3</button>}
            {!captchaValue && !loading && <button disabled>Preencha o captcha</button>}
          </>
        ) : (
          <>
            {loading && <button disabled>Aguarde...</button>}
            {!loading && <button onClick={handlePasswordFormScreen}>Já tenho conta</button>}
            <div className={`${styles.cancel_button} ${styles.box_button}`}>
              <button type="button" onClick={handleFisrtAccess}>Primeiro acesso</button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default SeePresentationScreen;