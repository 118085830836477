//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

//icons
import { FaFilePdf } from 'react-icons/fa';

//css
import styles from './CarouselComponent.module.css';

//interfaces
import { IContentCarousel } from '../../interfaces/IContentCarousel';

type Props = {
  slidesCount: number
  content: IContentCarousel[]
  currentSlide: number
  handleSlideChange(index: number): void
}

const CarouselComponent: React.FC<Props> = ({ slidesCount, content, currentSlide, handleSlideChange }) => {

  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={10}
      slidesPerView={slidesCount}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      pagination={{ clickable: true }}
      initialSlide={currentSlide}
    >
      {content.map((item, index) => (
        <SwiperSlide
          key={index}
          className={currentSlide === index ? styles.swiperSlideSelected : styles.swiperSlide}
          onClick={() => handleSlideChange(index)}
        >
          <div>
            <FaFilePdf size={40} color="#006930" />
          </div>
          <div style={{ textAlign: 'left' }}>
            <h3>{item.title}</h3>
          </div>
        </SwiperSlide>
      ))}
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </Swiper>
  );
};

export default CarouselComponent;
