// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__QwD\\+f {
    display: flex;
    justify-content: center;
    min-height: 88px;
    align-items: center;
    padding-left: 4em;
    padding-right: 4em;

    font-size: 14px;
    font-weight: 400;
    color: #727588;
    margin-top: auto;

    flex-wrap: wrap;
    -webkit-user-select: none;
            user-select: none;
}

.Footer_footer__QwD\\+f div {
    display: flex;
    gap: .8em;
}

.Footer_footer__QwD\\+f div svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    color: #3A683D;
}


@media (min-width: 1300px) and (min-height: 820px) {
    .Footer_fixed__-KKHZ {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0;
    }

    .Footer_fixed__-KKHZ div {
        padding-right: 4em;
    }

    .Footer_fixed__-KKHZ p {
        margin-left: 4em;
    }
}


@media (max-width: 490px) {
    .Footer_footer__QwD\\+f {
        margin-top: 8em;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;;IAElB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;;IAEhB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;;AAGA;IACI;QACI,kBAAkB;QAClB,SAAS;QACT,OAAO;QACP,WAAW;QACX,UAAU;IACd;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;;AAGA;IACI;QACI,eAAe;QACf,kBAAkB;QAClB,mBAAmB;QACnB,uBAAuB;IAC3B;AACJ","sourcesContent":[".footer {\n    display: flex;\n    justify-content: center;\n    min-height: 88px;\n    align-items: center;\n    padding-left: 4em;\n    padding-right: 4em;\n\n    font-size: 14px;\n    font-weight: 400;\n    color: #727588;\n    margin-top: auto;\n\n    flex-wrap: wrap;\n    user-select: none;\n}\n\n.footer div {\n    display: flex;\n    gap: .8em;\n}\n\n.footer div svg {\n    cursor: pointer;\n    width: 20px;\n    height: 20px;\n    color: #3A683D;\n}\n\n\n@media (min-width: 1300px) and (min-height: 820px) {\n    .fixed {\n        position: absolute;\n        bottom: 0;\n        left: 0;\n        width: 100%;\n        padding: 0;\n    }\n\n    .fixed div {\n        padding-right: 4em;\n    }\n\n    .fixed p {\n        margin-left: 4em;\n    }\n}\n\n\n@media (max-width: 490px) {\n    .footer {\n        margin-top: 8em;\n        text-align: center;\n        align-items: center;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__QwD+f`,
	"fixed": `Footer_fixed__-KKHZ`
};
export default ___CSS_LOADER_EXPORT___;
