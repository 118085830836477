import React from 'react';

//css
import styles from './Login.module.css';

//icons
import { FaRegCheckCircle } from 'react-icons/fa';

type Props = {
  handleBackLogin(e: React.FormEvent): void
}
const SeePasswordFormConcludedScreen: React.FC<Props> = ({ handleBackLogin }) => {
  return (
    <div className={styles.password_form_concluded}>
      <div className={styles.check_circle}>
        <FaRegCheckCircle size={32} />
      </div>
      <label>Cadastro realizado com sucesso</label>
      <div className={styles.confirm_button}>
        <button type="button" onClick={handleBackLogin}>Ir para autenticação</button>
      </div>
    </div>
  );
};

export default SeePasswordFormConcludedScreen;