//css
import styles from './ModalAnticipationInstallments.module.css';

//interfaces
import { IContract } from '../../../../interfaces/IContract';
import { IAccordSimulateResponseDto } from '../../../../interfaces/IAccordSimulateResponseDto ';
import { IAccordWithBankslipRequest } from '../../../../interfaces/IAccordWithBankslipRequest';
import { IProposalAccordSimulateDto } from '../../../../interfaces/IProposalAccordSimulateDto';
import { IGenericFileInfo } from '../../../../interfaces/IIncomeReport';

// modal
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

//utils
import { Formatters } from '../../../../utils/Formatters';
import { File } from '../../../../utils/File';
import { Toast } from '../../../../utils/Toast';

//components
import Loading from '../../../../components/loading/Loading';

//services
import { ProposalAccordService } from '../../../../services/proposalAccord/ProposalAccordService';

//api
import { ApiException } from '../../../../api/ApiException';

//react
import { useState } from 'react';

type Props = {
  open: boolean
  onCloseModal(): void
  contract: IContract | undefined
  accordSimulate: IAccordSimulateResponseDto
  dataSimulateConsult: IProposalAccordSimulateDto
  setData(value: any): void
  consultDataSimulate(): void
  selectedAll: boolean;
  setMessageAntecipation(value: any): void
}
const ModalAnticipationInstallments: React.FC<Props> = ({
                                                          open = false,
                                                          onCloseModal,
                                                          contract,
                                                          accordSimulate,
                                                          dataSimulateConsult,
                                                          setData,
                                                          consultDataSimulate,
                                                          selectedAll,
                                                          setMessageAntecipation,
                                                        }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const sendConsult = async () => {
    if (dataSimulateConsult) {
      setLoading(true);
      let request: IAccordWithBankslipRequest = {
        accordOperationNumber: dataSimulateConsult?.accordOperations[0]?.accordOperationNumber || '',
        dueDate: dataSimulateConsult?.dueDateBegin || '',
        installments: dataSimulateConsult?.accordOperations[0]?.installments,
      };

      ProposalAccordService.sendConsult(request).then((response) => {
        if (response instanceof ApiException) {
          Toast.error(response.message);
          setLoading(false);
        } else {
          const content: IGenericFileInfo = {
            base64: response.base64,
            customerEmail: response.customerEmail,
            customerName: response.customerName,
            fileName: response.customerName,
          };
          if (response.sentEmail) {
            Toast.success('O boleto de antecipação de parcelas foi enviado para o e-mail cadastrado.');
          }
          if (!response.sentEmail) {
            Toast.error('O boleto de antecipação de parcelas não foi enviado para o e-mail cadastrado.');
          }

          if (selectedAll) {
            const msg = 'Você antecipou todas as parcelas do seu contrato. Caso possua um seguro prestamista, entre em contato com nossa central de atendimento para verificar as informações.';
            setMessageAntecipation(msg);
            Toast.warning(msg);
          }

          setData(content);
          setLoading(false);
          consultDataSimulate();
          File.downloadPdf(response?.base64, response?.fileName);
          onCloseModal();
        }
      });

    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          if (!loading) onCloseModal();
        }}
        center
        showCloseIcon={false}>
        <div className={styles.container}>
          {loading && <Loading />}
          {accordSimulate && (
            <div className={styles.informationContainer}>
              <div className={styles.informations}>
                <label className={styles.information}>
                  Valor Original:
                  <strong>{accordSimulate?.originalValue && Formatters.formatMoneyInRealBrazil(accordSimulate?.originalValue)}</strong>
                </label>
                <label className={styles.information}>
                  Valor do Desconto:
                  <strong>{accordSimulate?.discountValue && Formatters.formatMoneyInRealBrazil(accordSimulate?.discountValue)}</strong>
                </label>
                <label className={styles.information}>
                  Valor Atualizado:
                  <strong>{accordSimulate?.updatedValue && Formatters.formatMoneyInRealBrazil(accordSimulate.updatedValue)}</strong>
                </label>
              </div>
              <div className={styles.actionButtons}>
                <button className={styles.notAnticipate} onClick={onCloseModal}>Não Antecipar</button>
                <button className={styles.anticipate} onClick={sendConsult}>Antecipar</button>
              </div>
            </div>
          )}
        </div>

      </Modal>
    </>
  );
};

export default ModalAnticipationInstallments;