//react
import { useEffect, useState } from 'react';

//components
import Error from '../../../components/error/Error';
import ViewPdf from '../../../components/viewPdf/ViewPdf';
import Loading from '../../../components/loading/Loading';

import CarouselComponent from '../../../components/carousel/CarouselComponent';

//interfaces
import { IContract } from '../../../interfaces/IContract';
import { IGenericFileInfo } from '../../../interfaces/IIncomeReport';
import { IContractSimplified } from '../../../interfaces/IContractSimplified';

//css
import styles from './ContractDoc.module.css';

//icons
import { MdOutlineMailOutline } from 'react-icons/md';
import { FaFileDownload } from 'react-icons/fa';

//utils
import { Toast } from '../../../utils/Toast';
import { File } from '../../../utils/File';
import { Formatters } from '../../../utils/Formatters';

//services
import { ContractService } from '../../../services/contract/ContractService';

//api
import { ApiException } from '../../../api/ApiException';

//moment
import moment from 'moment';
import ErrorSuccess from '../../../components/errorSuccess/ErrorSuccess';
import { IContractFiles } from '../../../interfaces/IContractFiles';

type Props = {
  id: string | undefined;
  contract: IContract | null;
};

const ContractDoc: React.FC<Props> = ({ id, contract }) => {
  const [files, setFiles] = useState<IContractFiles[]>();
  const [data, setData] = useState<IGenericFileInfo>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>('');
  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [contractSimplified, setContractSimplified] = useState<IContractSimplified>();
  const [currentSlide, setCurrentSlide] = useState(0);

  const downloadPDF = () => {
    if (data && data?.base64 && data?.fileName) {
      File.downloadPdf(data?.base64, data?.fileName);
    }
  };

  const sendEmail = async () => {
    if (files && files.length > 0) {
      setLoading(true);
      ContractService.sendEmailProposal(files[currentSlide].proposalCode || '', files[currentSlide].documentTypeCode || '').then((response) => {
        if (response instanceof ApiException) {
          Toast.error(response.message);
          setLoading(false);
        } else {
          Toast.success('Enviado com sucesso!');
          setLoading(false);
        }
      });
    }
  };

  const getContractInfo = async () => {
    setLoading(true);
    ContractService.getContractSimplified(contract?.cpfCnpj || '', contract?.contractCode || '', contract?.dataOrigin || '').then((response) => {
      if (response instanceof ApiException) {
        setMessageError(response.message);
      } else {
        setContractSimplified(response);
      }
    });
  };

  const getContractFiles = async () => {
    ContractService.getContractFiles(contract?.cpfCnpj || '', contract?.contractCode || '').then((response) => {
      if (response instanceof ApiException) {
        setMessageError(`${response.message}`);
        setShowMessageError(true);
        setHasError(false);
        setLoading(false);
      } else {
        if (response && response.length > 0) {
          setFiles(response);
          getDownloadProposal(response[0].proposalCode, response[0].documentTypeCode);
        } else {
          setLoading(false);
        }
      }
    });
  };

  const getDownloadProposal = async (proposalCode: string, documentTypeCode: string) => {
    setLoading(true);
    ContractService.getDownloadProposal(proposalCode || '', documentTypeCode || '').then((response) => {
      if (response instanceof ApiException) {
        setMessageError(`${response.message}`);
        setShowMessageError(true);
        setHasError(false);
      } else {
        setData(response);
      }
      setLoading(false);
    });
  };

  const handleSlideChange = (index: number) => {
    if (index === currentSlide) {
      return;
    }
    setCurrentSlide(index);
    if (files && files.length > 0) {
      getDownloadProposal(files[index].proposalCode, files[index].documentTypeCode);
    }
  };

  useEffect(() => {
    getContractInfo();
    getContractFiles();
  }, []);

  return (
    <div className={styles.container}>
      {loading && <Loading />}
      <div className={styles.top}>
        <h2 className={styles.tittle}>Contrato</h2>
        <div className={styles.informations}>
          <label className={styles.information}>
            Contrato
            <strong style={{ color: '#006930' }}>{contract?.contractCode}</strong>
          </label>
          <label className={styles.information}>
            Valor Total da Operação
            <strong>{contractSimplified?.totalValue && Formatters.formatMoneyInRealBrazil(contractSimplified?.totalValue)}</strong>
          </label>
          <label className={styles.information}>
            Data da Operação
            <strong>{contractSimplified?.beginDate && moment(contractSimplified?.beginDate).format('DD/MM/yyyy')}</strong>
          </label>
          <label className={styles.information}>
            Número de Parcelas
            <strong>{contractSimplified?.totalNumber}</strong>
          </label>
          <label className={styles.information}>
            Valor da Parcela
            <strong>{contractSimplified?.installmentValue && Formatters.formatMoneyInRealBrazil(contractSimplified?.installmentValue)}</strong>
          </label>
        </div>
      </div>
      <hr />
      <div className={styles.filter}>
        {files && files.length > 0 && (
          <>
            <div style={{ float: 'left', width: '65%' }}>
              <CarouselComponent
                slidesCount={3}
                content={files.map((item) => ({
                  title: item.documentTypeDescription,
                  description: item.proposalCode,
                }))}
                currentSlide={currentSlide}
                handleSlideChange={handleSlideChange}
              />
            </div>
            <div className={styles.actionButtons}>
              {<button className={styles.download} onClick={downloadPDF}><FaFileDownload />Baixar</button>}
              {sendEmail &&
                <button className={styles.sendEmail} onClick={sendEmail}><MdOutlineMailOutline />Enviar
                  para o E-mail</button>}
            </div>
          </>
        )}
      </div>
      {!loading && !data && (
        <>
          {!hasError && messageError && (
            <ErrorSuccess
              message={messageError}
              showCallCenter={true}
            />
          )}
          {hasError && <Error message={messageError} showMessage={showMessageError} />}
        </>
      )}
      {data && (
        <div>
          <br />
          <div style={{ width: '100%', paddingBottom: '56.25%', position: 'relative' }}>
            <ViewPdf
              base={data.base64}
              fileName={data.fileName}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractDoc;