// css
import styles from './Header.module.css';

//images
import Logo from '../../images/BBC_DIGITAL-LOGO_amarelo-1-01.png';

//utils
import { LocalStorageMethods } from '../../utils/LocalStorageMethods';

// context
import { AuthContext } from '../../hooks/HookUseContext';
import { ProfileInfoContext } from '../../hooks/ProfileUseContext';

//react
import { useContext } from 'react';

//consts
import { NAMES } from '../../consts/namesLocalStorage';

//icons
import { IoIosArrowDown } from 'react-icons/io';

const Header: React.FC = () => {
  const { setAuthValue } = useContext(AuthContext);
  const { profileValue } = useContext(ProfileInfoContext);

  const logout = () => {
    LocalStorageMethods.removeLocalStorage(NAMES.INFO_TOKEN);
    setAuthValue(null);
  };

  return (
    <header>
      <nav className={styles.container}>
        <img src={Logo} alt="LOGO BBC" />
        <div className={styles.profile}>
          <div className={styles.profile_icon}>
            {profileValue &&
              <p>{profileValue?.name?.split(' ').length >= 2
                ? profileValue?.name?.split(' ')[0][0]?.toString()?.toUpperCase() + profileValue?.name?.split(' ')[profileValue?.name?.split(' ').length - 1][0]?.toString()?.toUpperCase()
                : profileValue?.name?.split(' ')[0][0]?.toString()?.toUpperCase()}</p>
            }
          </div>
          <div className={styles.dropdown}>
            <div className={styles.dropbtn}>
              <IoIosArrowDown />
            </div>
            <div className={styles.dropdown_content}>
              <p className={styles.optionDropdown} onClick={logout}>Sair</p>
            </div>
          </div>
        </div>
      </nav>
      <div className={styles.footerHeader}></div>
    </header>
  );
};

export default Header;