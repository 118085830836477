//react
import React, { useEffect, useState } from 'react';

//datepicker
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, isWeekend } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';

//css
import styles from './DatePickerComponent.module.css';

registerLocale('pt-BR', ptBR);

type Props = {
  selectedDate: Date | null
  setSelectedDate(date: Date | null): void
  initial: number
  final: number
  holidays: Date[] // array de feriados
}
const DatePickerComponent: React.FC<Props> = ({ selectedDate, setSelectedDate, initial, final, holidays }) => {

  const minDate = addDays(new Date(), initial);
  const maxDate = addDays(new Date(), final);

  const [initialDateValue, setInitialDateValue] = useState<Date | null>(null);
  const isHoliday = (date: Date) => {
    return holidays.some(holiday =>
      holiday.getDate() === date.getDate() &&
      holiday.getMonth() === date.getMonth() &&
      holiday.getFullYear() === date.getFullYear(),
    );
  };

  const filterDate = (date: Date) => {
    return !isWeekend(date) && !isHoliday(date);
  };

  useEffect(() => {
    let currentDate = minDate;
    while (currentDate <= maxDate) {
      console.log(currentDate.toDateString());
      if (!isHoliday(currentDate) && !isWeekend(currentDate)) {
        setInitialDateValue(currentDate);
        setSelectedDate(currentDate);
        break;
      }
      currentDate = addDays(currentDate, 1);
    }
  }, [holidays]);

  return (
    <label className={styles.datePickerLabel}>
      Selecione uma data
      <DatePicker
        selected={selectedDate}
        onChange={(date: Date | null) => setSelectedDate(date)}
        minDate={initialDateValue}
        maxDate={maxDate}
        dateFormat="dd/MM/yyyy"
        locale="pt-BR"
        filterDate={filterDate} // Filtra as datas
      />
    </label>
  );
};

export default DatePickerComponent;
