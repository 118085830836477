//api
import { ApiException } from '../../api/ApiException';
import appAxios from '../../api/appAxios';
import { IAccordSimulateResponseDto } from '../../interfaces/IAccordSimulateResponseDto ';
import { IAccordWithBankslipRequest } from '../../interfaces/IAccordWithBankslipRequest';
import { IAccordWithBankslipResponse } from '../../interfaces/IAccordWithBankslipResponse';

//interfaces
import { IProposalAccordSimulateDto } from '../../interfaces/IProposalAccordSimulateDto';
import { IProposalAccordSimulateResponseDto } from '../../interfaces/IProposalAccordSimulateResponseDto';

const simulate = async (accordSimulateRequestDto: IProposalAccordSimulateDto): Promise<IAccordSimulateResponseDto | ApiException> => {
  try {
    const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_PROPOSAL_ACCORD_SIMULATE}`, accordSimulateRequestDto);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const consultSimulate = async (accordProposalNumber: string): Promise<IProposalAccordSimulateResponseDto | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API_PROPOSAL_ACCORD_SIMULATION_INFORMATION
      ?.replace('{accordProposalNumber}', accordProposalNumber)}`);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const consultDataSimulate = async (cpfcnpj: string, contractCode: string): Promise<IProposalAccordSimulateDto | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API_PROPOSAL_ACCORD_CONSULT_SIMULATION_INFORMATION
      ?.replace('{cpfcnpj}', cpfcnpj)
      ?.replace('{contractCode}', contractCode)}`);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const sendConsult = async (request: IAccordWithBankslipRequest): Promise<IAccordWithBankslipResponse | ApiException> => {
  try {
    const response = await appAxios.post(`${process.env.REACT_APP_ROUTE_API_PROPOSAL_ACCORD_SEND_CONSULT_SIMULATION_INFORMATION}`, request);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};


export const ProposalAccordService = {
  simulate,
  consultSimulate,
  consultDataSimulate,
  sendConsult,
};