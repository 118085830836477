// modal
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

// css
import styles from './ModalUpdateRegistration.module.css';

//react
import { useEffect, useRef, useState } from 'react';

//service
import { ClientService } from '../../../services/client/ClientService';

//exception
import { ApiException } from '../../../api/ApiException';
import { IDataRegistration } from '../../../interfaces/IDataRegistration';

// moment formatação data
import moment from 'moment';

// uttils
import { Formatters } from '../../../utils/Formatters';
import { Compare } from '../../../utils/Compare';
import { CreateIphoneNone } from '../../../utils/CreateIphoneNone';
import { Toast } from '../../../utils/Toast';

//interfaces
import { IPhone } from '../../../interfaces/IPhone';
import { ICheckPhoneChange } from '../../../interfaces/ICheckPhoneChange';

//components
import Loading from '../../../components/loading/Loading';
import Error from '../../../components/error/Error';
import WarningModal from '../../../components/warningModal/WarningModal';
import { ICepInformations } from '../../../interfaces/ICepInformations';


type Props = {
  open: boolean
  byTheButton: boolean
  onCloseModal(): void
}
const ModalUpdateRegistration: React.FC<Props> = ({ open = false, byTheButton = false, onCloseModal }) => {

  const [dataRegistration, setDataRegistration] = useState<IDataRegistration | any>();
  const [originalData, setOriginalData] = useState<IDataRegistration | any>();

  const buttonRef = useRef<any>(undefined);
  const inputCellPhone = useRef<any>(undefined);

  const [cellPhone, setCellPhone] = useState<IPhone | any>(undefined);
  const [homePhone, setHomePhone] = useState<IPhone | any>(undefined);
  const [workPhone, setWorkPhone] = useState<IPhone | any>(undefined);

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [hasError, setHasError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>('');

  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [qtdRequired, setQtdRequired] = useState<any>(undefined);

  const [checkPhone, setCheckPhone] = useState<ICheckPhoneChange | any>();

  const [token, setToken] = useState<any>();

  const [cepIsNotValid, setCepIsNotValid] = useState<boolean>(false);
  const [addressName, setAddressName] = useState<boolean>(false);
  const [city, setCity] = useState<boolean>(false);
  const [neighborhood, setNeighborhood] = useState<boolean>(false);
  const [state, setState] = useState<boolean>(false);
  const [country, setCountry] = useState<boolean>(false);

  const messageModal: string | undefined = process.env.REACT_APP_WARNING_MODAL_UPDATE_MESSAGE?.replace('{horas}', dataRegistration?.amountHoursForUpdate);

  const handleInvalidInput = (event: any) => {
    event.preventDefault();
    if (!event.target.value)
      event.target.classList.add('required');

    setQtdRequired(qtdRequired === undefined ? 1 : qtdRequired + 1);
  };

  const handleInputBlur = () => {
  };

  const handleOpenSecondModal = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const phone = cellPhone?.id?.phoneNumber;
    ClientService.checkPhoneChange(phone).then((response) => {
      if (response instanceof ApiException) {
        Toast.error(response.message);
      } else {
        const checkPhoneChange: ICheckPhoneChange = response;
        setCheckPhone(checkPhoneChange);
        setOpenSecondModal(true);
      }
      setLoading(false);
    });

  };

  const onCloseSecondModal = () => {
    setOpenSecondModal(false);
  };

  const handleUpdateRegistration = () => {
    setOpenSecondModal(false);
    dataRegistration.otp = token;
    dataRegistration.phones = [];
    dataRegistration?.phones?.push(cellPhone);
    dataRegistration?.phones?.push(homePhone);
    dataRegistration?.phones?.push(workPhone);

    setLoading(true);
    ClientService.updateRegistrationInformation(dataRegistration).then((response) => {
      if (response instanceof ApiException) {
        setMessageError(response.message);
        Toast.error(response.message);
      } else {

        const dataRegistration: IDataRegistration = response;
        setDataRegistration(dataRegistration);
        setOriginalData(dataRegistration);
        Toast.success('Sucesso!');
        if (dataRegistration?.phones > 0) {
          dataRegistration?.phones?.map((phone: IPhone) => {
            if (phone?.id?.type === 'HOME_PHONE' && !phone?.temporary) {
              setHomePhone(phone);
            } else if (phone?.id?.type === 'WORK_PHONE' && !phone?.temporary) {
              setWorkPhone(phone);
            } else if (phone?.id?.type === 'CELL_PHONE' && !phone?.temporary) {
              setCellPhone(phone);
            }
          });
        } else {
          setHomePhone(CreateIphoneNone('HOME_PHONE'));
          setWorkPhone(CreateIphoneNone('WORK_PHONE'));
          setCellPhone((CreateIphoneNone('CELL_PHONE')));
        }
        onCloseModal();

      }
      setLoading(false);
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      e.target.value = e.target.value.replace(/\s+/g, ' ');
      if (e.target.value === ' ') {
        e.target.value = '';
      }
    }
    const [section, key] = e.target.name.split('.');
    if (section === 'address' && key !== 'number') {
      e.target.value = e.target.value.replace(/[\(\)|<>%&$;'\"~/\\]/g, '');
    } else if (section === 'address' && key === 'number') {
      e.target.value = e.target.value.replace(/[\(\)|<>%&$;'\"~\\]/g, '');

    }

    if (section === 'juridicalNature' || section === 'associateRelationship' || section === 'majorityAssociateAddress') {
      e.target.value = e.target.value.replace(/[\(\)|<>%&$;'\"~/\\]/g, '');
    }

    // section is : company
    // key is : position

    if (key) {
      // if you have nested keys to update
      setDataRegistration({
        ...dataRegistration,
        [section]: {
          ...dataRegistration[section],
          [key]: e.target.value,
        },
      });
    } else {
      // if you're updating on the first level
      setDataRegistration({
        ...dataRegistration,
        [section]: e.target.value,
      });
    }

  };

  const handleCancelUpdate = () => {
    setDataRegistration(originalData);
    onCloseModal();
  };

  const hasClassByName = (className: any, inputName: any) => {
    const elements = document.querySelectorAll(`input[name="${className}"].${inputName}`);
    return elements.length > 0;
  };

  const setDisabledInputFunction = (data: any, thisFunction: any) => {
    if (data) thisFunction(true);
    else thisFunction(false);
  };

  const handleChangeApiCep = (cep: string, hasMessageError: boolean) => {
    if (cep && cep.length === 9) {
      ClientService.postCepInformations({ zipCode: cep }).then((response) => {
        if (response instanceof ApiException) {
          setCepIsNotValid(true);
          dataRegistration.address.zipCode = cep;
          dataRegistration.address.addressName = '';
          dataRegistration.address.city = '';
          dataRegistration.address.neighborhood = '';
          dataRegistration.address.state = '';
          dataRegistration.address.country = '';
          if (hasMessageError) {
            Toast.error(response.message);
          }
          setDisabledInputs();
        } else {
          setCepIsNotValid(false);
          setDataDisableInputs(response);
          setDisabledInputs();

        }
        setDataRegistration(dataRegistration);
      });
    }
  };
  const setDataDisableInputs = (response: ICepInformations) => {
    const cepInformations: ICepInformations = response;
    dataRegistration.address.zipCode = Formatters.zipCodeMask(cepInformations.zipCode);
    dataRegistration.address.addressName = cepInformations.name;
    dataRegistration.address.city = cepInformations.city || dataRegistration.address.city;
    dataRegistration.address.neighborhood = cepInformations.neighborhood;
    dataRegistration.address.state = cepInformations.uf || dataRegistration.address.state;
    dataRegistration.address.country = cepInformations.country || dataRegistration.address.country;
  };

  const setDisabledInputs = () => {
    setDisabledInputFunction(dataRegistration.address.addressName, setAddressName);
    setDisabledInputFunction(dataRegistration.address.city, setCity);
    setDisabledInputFunction(dataRegistration.address.neighborhood, setNeighborhood);
    setDisabledInputFunction(dataRegistration.address.state, setState);
    setDisabledInputFunction(dataRegistration.address.country, setCountry);
  };


  useEffect(() => {
    setLoadingData(true);
    ClientService.findRegistrationInformation().then((response) => {
      if (response instanceof ApiException) {
        setHasError(true);
        setMessageError(response.message);
      } else {
        const dataRegistration: IDataRegistration = response;
        dataRegistration.address.zipCode = Formatters.zipCodeMask(dataRegistration?.address?.zipCode);
        setDataRegistration(dataRegistration);
        setOriginalData(dataRegistration);
      }
      setLoadingData(false);
    });
  }, []);
  useEffect(() => {
    dataRegistration?.phones?.map((phone: IPhone) => {
      if (phone?.id?.type === 'HOME_PHONE' && !phone?.temporary) {
        phone.id.phoneNumber = Formatters.phoneMaskSimple(phone.id.phoneNumber);
        setHomePhone(phone);
      } else if (phone?.id?.type === 'WORK_PHONE' && !phone?.temporary) {
        phone.id.phoneNumber = Formatters.phoneMaskSimple(phone.id.phoneNumber);
        setWorkPhone(phone);
      } else if (phone?.id?.type === 'CELL_PHONE' && !phone?.temporary) {
        phone.id.phoneNumber = Formatters.phoneMaskSimple(phone.id.phoneNumber);
        setCellPhone(phone);
      }

    });
    if (homePhone === undefined) setHomePhone(CreateIphoneNone('HOME_PHONE'));
    if (workPhone === undefined) setWorkPhone(CreateIphoneNone('WORK_PHONE'));
    if (cellPhone === undefined) setCellPhone((CreateIphoneNone('CELL_PHONE')));


  }, [originalData]);

  useEffect(() => {
    if (originalData) {
      setDisabledInputs();
    }
  }, [originalData]);

  return (
    <>
      {openSecondModal && (
        <WarningModal
          title="Atualizar dados cadastrais?"
          message={messageModal || ''}
          openSecondModal={openSecondModal}
          onCloseSecondModal={onCloseSecondModal}
          handleEvent={handleUpdateRegistration}
          checkPhoneChange={checkPhone}
          token={token}
          setToken={setToken}
        />
      )}
      {loadingData ? <Loading /> : (


        <Modal
          open={open}
          onClose={byTheButton && Compare.isEquivalent(dataRegistration, originalData)
            ? onCloseModal
            : () => buttonRef?.current?.focus()}
          center
          showCloseIcon={false}>
          <> {!hasError ? (
            <>

              <h1 className={styles.modalTitle}>Atualização cadastral</h1>
              <form className={styles.formContainer} onSubmit={(e) => {
                e.preventDefault();
                if (cepIsNotValid) {
                  Toast.error('Insira um CEP válido!');
                  return;
                }
                handleOpenSecondModal(e);
              }}>
                <label className={styles.item}>
                  Nome/Razão social
                  <input type="text" disabled value={dataRegistration?.name || ''} />
                </label>
                <label className={styles.item}>
                  E-mail
                  <input
                    maxLength={128}
                    type="email" name="email"
                    value={dataRegistration?.email?.toString()?.toLowerCase() || ''}
                    onChange={(e) => {
                      e.target.value = e.target.value.toLowerCase();
                      handleChange(e);
                    }} />
                </label>

                <div className={styles.break}></div>
                {dataRegistration?.typePerson !== 'JURIDICAL_PERSON' && (
                  <>
                    <label className={styles.item}>
                      Sexo
                      <input
                        maxLength={10}
                        type="text" disabled name="gender"
                        value={dataRegistration?.gender || ''} />
                    </label>
                    <label className={styles.item}>
                      Data de nascimento
                      <input type="text" disabled
                             value={dataRegistration?.birthDate ? moment(dataRegistration?.birthDate).format('DD/MM/yyyy') : ''} />
                    </label>
                  </>
                )}
                <label className={styles.item}>
                  CPF/CNPJ
                  <input type="text" disabled
                         value={Formatters.formatterCpfCnpj(dataRegistration?.cpfCnpj || '')} />
                </label>

                <div className={styles.break}></div>

                <label className={styles.item}>
                  Celular
                  <input ref={inputCellPhone} placeholder="(99) 99999-9999"
                         pattern="\(\d{2}\) \d{5}-\d{4}" type="text" required maxLength={15}
                         name="id.phoneNumber"
                         className={(!cellPhone?.id?.phoneNumber) || (cellPhone?.id?.phoneNumber && cellPhone?.id?.phoneNumber && !/^\(\d{2}\) \d{5}-\d{4}$/?.test(cellPhone?.id?.phoneNumber)) ? 'required' : ''}
                         onInvalid={handleInvalidInput} onBlur={handleInputBlur}
                         value={Formatters.phoneMaskSimple(cellPhone?.id?.phoneNumber)}
                         onChange={(e) => {
                           setCellPhone({
                             ...cellPhone,
                             id: {
                               ...cellPhone?.id,
                               phoneNumber: Formatters.phoneMaskSimple(e.target.value),
                             },
                           });
                         }} />
                  <>
                    {(hasClassByName('id.phoneNumber', 'required') && !cellPhone?.id?.phoneNumber) &&
                      <p className={styles.inputRequiredText}>Telefone obrigatório</p>}
                    {cellPhone?.id?.phoneNumber && cellPhone?.id?.phoneNumber && !/^\(\d{2}\) \d{5}-\d{4}$/?.test(cellPhone?.id?.phoneNumber) &&
                      <p className={styles.inputRequiredText}>Formato inválido</p>}
                  </>
                </label>
                <label className={styles.item}>
                  Telefone Residencial

                  <input onInvalid={handleInvalidInput} onBlur={handleInputBlur} type="text"
                         pattern="\(\d{2}\) \d{4,5}-\d{4}" name="id.phoneNumber" maxLength={15}
                         className={(homePhone === undefined || homePhone === '') ? '' : 'required'}
                         value={Formatters.phoneMaskSimple(homePhone?.id?.phoneNumber)}
                         onChange={(e) => {
                           setHomePhone({
                             ...homePhone,
                             id: {
                               ...homePhone?.id,
                               phoneNumber: Formatters.phoneMaskSimple(e.target.value),
                             },
                           });
                         }} />
                  {homePhone?.id?.phoneNumber && homePhone?.id?.phoneNumber && !/^\(\d{2}\) \d{4,5}-\d{4}$/?.test(homePhone?.id?.phoneNumber) &&
                    <p className={styles.inputRequiredText}>Formato inválido</p>}
                </label>
                <label className={styles.item}>
                  Telefone Comercial
                  <input onInvalid={handleInvalidInput} onBlur={handleInputBlur} type="text"
                         pattern="\(\d{2}\) \d{4,5}-\d{4}" name="id.phoneNumber" maxLength={15}
                         className={(workPhone === undefined || workPhone === '') ? '' : 'required'}
                         value={Formatters.phoneMaskSimple(workPhone?.id?.phoneNumber)}
                         onChange={(e) => {
                           setWorkPhone({
                             ...workPhone,
                             id: {
                               ...workPhone?.id,
                               phoneNumber: Formatters.phoneMaskSimple(e.target.value),
                             },
                           });
                         }} />
                  {workPhone?.id?.phoneNumber && workPhone?.id?.phoneNumber && !/^\(\d{2}\) \d{4,5}-\d{4}$/?.test(workPhone?.id?.phoneNumber) &&
                    <p className={styles.inputRequiredText}>Formato inválido</p>}
                </label>

                <div className={styles.break}></div>
                <label className={styles.item}>
                  CEP
                  <input
                    pattern="^\d{5}-\d{3}$"
                    className={((!dataRegistration?.address?.zipCode) || (dataRegistration?.address?.zipCode && !/^\d{5}-\d{3}$/?.test(dataRegistration?.address?.zipCode))) ? 'required' : ''}
                    onInvalid={handleInvalidInput}
                    onBlur={handleInputBlur}
                    type="text"
                    maxLength={9}
                    required
                    name="address.zipCode"
                    value={Formatters.zipCodeMask(dataRegistration?.address?.zipCode)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = Formatters.zipCodeMask(e.target.value);
                      handleChangeApiCep(e.target.value, true);
                      handleChange(e);
                    }} />
                  {(!dataRegistration?.address?.zipCode) &&
                    <p className={styles.inputRequiredText}>CEP obrigatório</p>}
                  {dataRegistration?.address?.zipCode.length > 0 && dataRegistration?.address?.zipCode.length < 9 && !/^\d{5}-\d{3}$/?.test(dataRegistration?.address?.zipCode) &&
                    <p className={styles.inputRequiredText}>Formato inválido</p>}
                </label>
                <label className={styles.item_wide}>
                  Endereço
                  <input
                    maxLength={128}
                    disabled={(cepIsNotValid || addressName)}
                    onInvalid={handleInvalidInput} onBlur={handleInputBlur} type="text" required
                    name="address.addressName" value={dataRegistration?.address?.addressName || ''}
                    onChange={handleChange} />
                  {(qtdRequired && hasClassByName('address.addressName', 'required') && !dataRegistration?.address?.addressName) &&
                    <p className={styles.inputRequiredText}>Endereço obrigatório</p>}
                </label>

                <div className={styles.break}></div>

                <label className={styles.item}>
                  Número
                  <input
                    maxLength={16}
                    onInvalid={handleInvalidInput} onBlur={handleInputBlur} type="text" required
                    name="address.number" value={dataRegistration?.address?.number || ''}
                    onChange={handleChange} />
                  {(qtdRequired && hasClassByName('address.number', 'required') && !dataRegistration?.address?.number) &&
                    <p className={styles.inputRequiredText}>Número obrigatório ou insira S/N</p>}
                </label>
                <label className={styles.item}>
                  Bairro
                  <input
                    maxLength={100}
                    disabled={(cepIsNotValid || neighborhood)}
                    onInvalid={handleInvalidInput} onBlur={handleInputBlur} type="text" required
                    name="address.neighborhood"
                    value={dataRegistration?.address?.neighborhood || ''} onChange={handleChange} />
                  {(qtdRequired && hasClassByName('address.neighborhood', 'required') && !dataRegistration?.address?.neighborhood) &&
                    <p className={styles.inputRequiredText}>Bairro obrigatório</p>}
                </label>
                <label className={styles.item}>
                  Complemento
                  <input
                    maxLength={128}
                    type="text" name="address.complement"
                    value={dataRegistration?.address?.complement || ''} onChange={handleChange} />
                </label>

                <div className={styles.break}></div>

                <label className={styles.item}>
                  Cidade
                  <input
                    maxLength={64}
                    disabled={(cepIsNotValid || city)}
                    onInvalid={handleInvalidInput} onBlur={handleInputBlur} type="text" required
                    name="address.city" value={dataRegistration?.address?.city || ''}
                    onChange={handleChange} />
                  {(qtdRequired && hasClassByName('address.city', 'required') && !dataRegistration?.address?.city) &&
                    <p className={styles.inputRequiredText}>Cidade obrigatório</p>}
                </label>
                <label className={styles.item}>
                  Estado
                  <input
                    maxLength={32}
                    disabled={(cepIsNotValid || state)}
                    onInvalid={handleInvalidInput} onBlur={handleInputBlur} type="text" required
                    name="address.state" value={dataRegistration?.address?.state || ''}
                    onChange={handleChange} />
                  {qtdRequired && hasClassByName('address.state', 'required') && !dataRegistration?.address?.state &&
                    <p className={styles.inputRequiredText}>Estado obrigatório</p>}
                </label>
                <label className={styles.item}>
                  País
                  <input
                    maxLength={32}
                    disabled={(cepIsNotValid || country)}
                    onInvalid={handleInvalidInput} onBlur={handleInputBlur} type="text" required
                    name="address.country" value={dataRegistration?.address?.country || ''}
                    onChange={handleChange} />
                  {(qtdRequired && hasClassByName('address.country', 'required') && !dataRegistration?.address?.country) &&
                    <p className={styles.inputRequiredText}>País obrigatório</p>}
                </label>

                <div className={styles.break}></div>

                {dataRegistration?.typePerson === 'JURIDICAL_PERSON' && (
                  <>
                    <label className={styles.item}>
                      Natureza jurídica
                      <input
                        maxLength={64}
                        type="text" onInvalid={handleInvalidInput} onBlur={handleInputBlur}
                        required name="juridicalNature"
                        value={dataRegistration?.juridicalNature || ''}
                        onChange={handleChange} />
                      {(qtdRequired && hasClassByName('juridicalNature', 'required') && !dataRegistration?.juridicalNature) &&
                        <p className={styles.inputRequiredText}>Natureza jurídica
                          obrigatório</p>}
                    </label>
                    <label className={styles.item}>
                      Relacionamento associado
                      <input
                        maxLength={64}
                        type="text" onInvalid={handleInvalidInput} onBlur={handleInputBlur}
                        required name="associateRelationship"
                        value={dataRegistration?.associateRelationship || ''}
                        onChange={handleChange} />
                      {(qtdRequired && hasClassByName('associateRelationship', 'required') && !dataRegistration?.associateRelationship) &&
                        <p className={styles.inputRequiredText}>Relacionamento associado
                          obrigatório</p>}
                    </label>
                    <label className={styles.item}>
                      Endereço de associado majoritário
                      <input
                        maxLength={128}
                        type="text" onInvalid={handleInvalidInput} onBlur={handleInputBlur}
                        required name="majorityAssociateAddress"
                        value={dataRegistration?.majorityAssociateAddress || ''}
                        onChange={handleChange} />
                      {(qtdRequired && hasClassByName('majorityAssociateAddress', 'required') && !dataRegistration?.majorityAssociateAddress) &&
                        <p className={styles.inputRequiredText}>Endereço de associado
                          majoritário obrigatório</p>}
                    </label>
                  </>
                )}
                <div className={styles.actions}>
                  {byTheButton && !loading &&
                    <input type="button" value="Cancelar" onClick={handleCancelUpdate} />}
                  {byTheButton && loading && <input type="button" value="Cancelar" disabled />}
                  {!loading && (
                    <button ref={buttonRef}>Atualizar</button>
                  )}
                  {loading && (
                    <button className={styles.disabledBtn} disabled>Aguarde...</button>
                  )}
                </div>
              </form>
            </>
          ) : <Error message={messageError} />}
          </>
        </Modal>
      )}
    </>
  );
};

export default ModalUpdateRegistration;