//styles
import styles from './Error.module.css';

// icons
import { BiError } from 'react-icons/bi';

type Props = {
  message: string,
  showMessage?: boolean
}
const Error: React.FC<Props> = ({ message, showMessage = false }) => {
  return (
    <div className={styles.container}>
      <BiError />
      <div className={styles.containerMessage}>
        {showMessage && (
          <p>{message}</p>
        )}
        {!showMessage && (
          <div>
            <div>
              <h2>Ops, Algo deu errado</h2>
            </div>
            <p>Por favor, tente novamente.</p>
            <p>Se o problema persistir, entre em contato com o nosso atendimento:</p>
          </div>
        )}

        <h3>Central de Atendimento 0800 771 4080</h3>
        <button onClick={() => window.location.reload()}>Recarregar</button>
      </div>
    </div>
  );
};

export default Error;