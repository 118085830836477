export function CreateIphoneNone(type: string) {
  const id = {
    customerId: null,
    phoneNumber: '',
    type: type,
  };

  const iPhone = {
    id: id,
    forToken: false,
  };

  return iPhone;
}