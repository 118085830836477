import { Navigate, Route, Routes } from 'react-router-dom';

//pages
import Main from '../pages/main/Main';
import Contract from '../pages/contract/Contract';


const LoggedAuth: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Main />} />
      <Route path="/contract" element={<Contract />} />
      <Route path="/*" element={<Navigate to="/home" />} />
    </Routes>
  );
};

export default LoggedAuth;