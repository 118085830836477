//exception
import { ApiException } from '../../api/ApiException';

//appAxios
import appAxios from '../../api/appAxios';

//interfaces
import { IHolidaysResponseDto } from '../../interfaces/IHolidaysResponseDto';


const getAllHolidays = async (): Promise<IHolidaysResponseDto | ApiException> => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_ROUTE_API_HOLIDAYS?.replace('{year}', String(new Date().getFullYear()))}`);
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};


export const HolidaysService = {
  getAllHolidays,

};