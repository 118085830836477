//react
import React, { useState } from 'react';

//css
import styles from './Login.module.css';

//components
import Input from '../../components/input/Input';

//icons
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// reacptcha
import ReCAPTCHA from 'react-google-recaptcha';
import { RecaptchaGoogleService } from '../../services/recaptcha_google/RecaptchaGoogleService';
import { IRecaptchaGoogleResponse } from '../../interfaces/IRecaptchaGoogleResponse';
import { ApiException } from '../../api/ApiException';

type Props = {
  handleSubmitPasswordForm(e: React.FormEvent, captcha: any): void
  cpfCnpj: string
  setCpfCnpj(cpfCnpj: string): void
  Formatters: any
  loading: boolean
  password: string
  setPassword(password: string): void
  confirmPassword: string
  setConfirmPassword(confirmPassword: string): void
  message: string
  Message: any,
  seePassword(id: string): string
  handleBackLogin(e: React.FormEvent): void,
  passwordLabel: string,
  confirmPasswordLabel: string,
  title: string,
  attemps: number,
  setMessage(message: string): void,
  setLoading(loading: boolean): void,
  Logo: string,
  SmallLogo: string,
}
const SeePasswordFormScreen: React.FC<Props> = (
  {
    handleSubmitPasswordForm,
    cpfCnpj,
    setCpfCnpj,
    Formatters,
    loading,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    message,
    Message,
    seePassword,
    handleBackLogin,
    passwordLabel = 'Senha',
    confirmPasswordLabel = 'Confirmar Senha',
    title = 'Cadastre-se',
    attemps,
    setMessage,
    setLoading,
    Logo,
    SmallLogo,
  },
) => {
  const [passwordId, setPasswordId] = useState('password');
  const [confirmPasswordId, setConfirmPasswordId] = useState('password');
  const [requiredPassword, setRequiredPassword] = useState<boolean>(false);
  const [requiredConfirmPassword, setRequiredConfirmPassword] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<any>();
  const key_site = process.env.REACT_APP_RECAPTCHA_KEY_SITE;
  const key_secret = process.env.REACT_APP_RECAPTCHA_KEY_SECRET;
  let captcha: any;
  const [inputPassword, setInputPassword] = useState('');
  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setInputPassword(newPassword);
    validatePassword(newPassword);
  };
  const [criteria, setCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false,
  });
  const validatePassword = (value: any) => {
    setCriteria({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      number: /[0-9]/.test(value),
    });
  };
  const handleInvalidInput = (event: any) => {
    event.preventDefault();
    if (!event.target.value)
      event.target.classList.add('required');
    if (event.target.name === 'password') setRequiredPassword(true);
    if (event.target.name === 'confirmPassword') setRequiredConfirmPassword(true);
  };
  const handleInputBlur = () => {
  };
  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return captcha = ref;
    }
  };
  const onChange = (value: any) => {
    setCaptchaValue(value);
    setMessage('');
  };
  return (
    <div className={styles.container_password}>
      <div className={styles.item_password}>
        <form onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (!password || !cpfCnpj) {
            return;
          }
          setLoading(true);
          if (attemps > 1 && key_site && key_secret) {
            const dados = {
              response: captchaValue,
            };
            RecaptchaGoogleService.sendTokenRecaptcha(dados).then((response) => {
              if (response instanceof ApiException) {
                captcha?.reset();
                setCaptchaValue(null);
                setLoading(false);
              } else {
                const recaptchaGoogleResponse: IRecaptchaGoogleResponse = response;
                if (recaptchaGoogleResponse.success) {
                  handleSubmitPasswordForm(e, captcha);
                  setCaptchaValue(null);
                }
              }
            });
          } else {
            handleSubmitPasswordForm(e, null);
          }

        }}>
          <img className={styles.logoImg} src={Logo} alt="logo BBC" />
          <div className={styles.subtitle}>
            <span>{title}</span>
          </div>
          <label>CPF/CNPJ<span className={styles.asterisk}>*</span></label>
          <Input
            disabled={true}
            placeholder="Digite seu CPF/CNPJ"
            value={cpfCnpj}
            onChange={(e) =>
              setCpfCnpj(Formatters.formatterCpfCnpj(e.target.value))
            }
            required
          />
          <label>{passwordLabel} <span className={styles.asterisk}>*</span></label>
          <Input
            name="password"
            id="passwordId"
            disabled={loading}
            type="password"
            placeholder="Insira sua senha"
            value={password}
            maxLength={15}
            onChange={handlePasswordChange}
            required
            onInvalid={handleInvalidInput}
            onBlur={handleInputBlur}
          />
          <div className={styles.show_password} onClick={() => setPasswordId(seePassword('passwordId'))}>
            {passwordId === 'password' && <FaEye />}
            {passwordId !== 'password' && <FaEyeSlash />}
          </div>
          {requiredPassword && !password && <span className="inputRequiredPassword">Senha obrigatório</span>}
          <label>{confirmPasswordLabel}</label>
          <Input
            name="confirmPassword"
            id="confirmPasswordId"
            disabled={loading}
            type="password"
            placeholder="Confirme sua senha"
            value={confirmPassword}
            maxLength={15}
            onChange={(e) =>
              setConfirmPassword(e.target.value)
            }
            required
            onInvalid={handleInvalidInput}
            onBlur={handleInputBlur}
          />
          <div className={styles.show_password}
               onClick={() => setConfirmPasswordId(seePassword('confirmPasswordId'))}>
            {confirmPasswordId === 'password' && <FaEye />}
            {confirmPasswordId !== 'password' && <FaEyeSlash />}
          </div>
          {requiredConfirmPassword && !confirmPassword &&
            <span className="inputRequiredPassword">Confirmação de senha obrigatório</span>}
          <div className={styles.captcha}>
            {attemps > 1 && key_site && key_secret && (
              <ReCAPTCHA
                ref={(r) => setCaptchaRef(r)}
                className={styles.captcha}
                sitekey={key_site}
                onChange={onChange}
              />
            )}
          </div>
          {message && <Message message={message} type="error_login" />}
          <div className={styles.box_button}>
            <div className={styles.confirm_button}>
              {attemps > 1 && key_site && key_secret ? (
                <>
                  {loading && <button disabled>Aguarde...</button>}
                  {captchaValue && !loading && <button>Continuar</button>}
                  {!captchaValue && !loading && <button disabled>Preencha o captcha</button>}
                </>
              ) : (
                <>
                  {loading && <button disabled>Aguarde...</button>}
                  {!loading && <button>Continuar</button>}
                  <div className={`${styles.cancel_button} ${styles.box_button}`}>
                    {<button type="button" onClick={handleBackLogin}>Cancelar</button>}
                  </div>
                </>
              )}
            </div>
          </div>

        </form>
      </div>
      <div className={styles.item_message}>
        <span className={styles.title}>A senha deve conter:</span>
        <ul>
          <li className={criteria.length ? styles.valid : styles.invalid}>8 caracteres</li>
          <li className={criteria.uppercase ? styles.valid : styles.invalid}>Letra maiúscula</li>
          <li className={criteria.lowercase ? styles.valid : styles.invalid}>Letra minúscula</li>
          <li className={criteria.specialChar ? styles.valid : styles.invalid}>Caractere especial</li>
          <li className={criteria.number ? styles.valid : styles.invalid}>Número</li>
        </ul>
      </div>
    </div>
  );
};

export default SeePasswordFormScreen;