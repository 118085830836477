const downloadPdf = (base64: string, nameFile: string) => {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement('a');
  const fileName = nameFile;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const File = {
  downloadPdf,
};