import { Navigate, Route, Routes } from 'react-router-dom';

//pages
import Index from '../pages/login/Index';


//pages

const LoggedOutAuth: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default LoggedOutAuth;