// modal
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

//css
import styles from './WarningModal.module.css';
import Message from '../message/Message';

//react
import { useEffect, useState } from 'react';
import { ICheckPhoneChange } from '../../interfaces/ICheckPhoneChange';
import { ClientService } from '../../services/client/ClientService';
import { ApiException } from '../../api/ApiException';


const duration = process.env.REACT_APP_TIME_RESEND_TOKEN;
const text = process.env.REACT_APP_TIME_DURATION_TEXT_TOKEN;

type Props = {
  openSecondModal: boolean
  onCloseSecondModal(): void
  title: string
  message: string
  handleEvent(): void
  checkPhoneChange: ICheckPhoneChange
  token: string
  setToken(token: string): void
}
const WarningModal: React.FC<Props> = ({
                                         openSecondModal,
                                         onCloseSecondModal,
                                         title,
                                         message,
                                         handleEvent,
                                         checkPhoneChange,
                                         token,
                                         setToken,
                                       }) => {

  const [requiredToken, setRequiredToken] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const [view, setView] = useState<boolean>(false);


  const [timer, setTimer] = useState<any>('00:00');
  const [loadingResendToken, setLoadingResendToken] = useState<boolean>(false);
  const [isCount, setIsCount] = useState<boolean>(false);

  const handleInvalidInput = (event: any) => {
    event.preventDefault();
    if (!event.target.value)
      event.target.classList.add('required');
    if (event.target.name === 'token') setRequiredToken(true);
  };

  const handleInputBlur = () => {
  };

  const handleSubmitToken = (e: any) => {
    e.preventDefault();
    setView(true);
  };

  const handleResendToken = () => {
    setLoadingResendToken(true);
    ClientService.resendOtp().then((response) => {
      if (response instanceof ApiException) {
      } else {
      }
      setLoadingResendToken(false);
      startTimer();
    });


  };

  function startTimer() {
    setIsCount(true);
    var timerL: any = duration;
    var minutes, seconds;

    if (timerL)
      timerL = timerL * 60;

    var timeInterval = setInterval(function() {
      minutes = parseInt(String(timerL / 60), 10);
      seconds = parseInt(String(timerL % 60), 10);
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      if (--timerL < 0) {
        setIsCount(false);
        clearInterval(timeInterval);
      }
      setTimer(`${minutes}:${seconds}`);
    }, 1000);


  }

  useEffect(() => {
    startTimer();
  }, []);

  return (
    <Modal
      open={openSecondModal}
      onClose={onCloseSecondModal}
      center
      showCloseIcon={false}>
      <div className={styles.container}>

        {(!checkPhoneChange.different || view) && (
          <>
            <h2>{title}</h2>
            {<p>{message}</p>}
            <div className={styles.actions}>
              <button onClick={onCloseSecondModal}>Cancelar</button>
              <button className={styles.submit} onClick={handleEvent}>Sim</button>
            </div>
          </>
        )}

        {checkPhoneChange.different && !view && (
          <>
            <h2>{title}</h2>
            <form onSubmit={handleSubmitToken}>
              <div className={styles.token}>
                Token:
                <input
                  name="token"
                  disabled={loading}
                  placeholder="Digite seu token"
                  value={token}
                  maxLength={6}
                  onChange={(e) => setToken(e.target.value)}
                  required
                  onInvalid={handleInvalidInput}
                  onBlur={handleInputBlur} />
              </div>
              {requiredToken && !token && <p className="inputRequired">Token obrigatório</p>}
              {messageError && <Message message={messageError} type="error_login" />}
              <div className={styles.sub_items_footer}>
                <span>Seu token expira em <span className={styles.sub_items_bold}>{text}</span></span>
              </div>
              {loadingResendToken ? (
                <span className={styles.sub_items}>Aguarde...</span>
              ) : (
                <>
                  {!isCount ? (
                    <span className={styles.sub_items}
                          onClick={handleResendToken}> Reenviar token</span>
                  ) : <p>{timer}</p>}
                </>
              )}
              <div className={styles.actions}>
                <button onClick={onCloseSecondModal}>Cancelar</button>
                <button className={styles.submit}>Sim</button>
              </div>
            </form>
          </>
        )}


      </div>
    </Modal>
  );
};

export default WarningModal;