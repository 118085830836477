// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Message_message__cjLNc {
    width: auto;
    padding: 1em;
    border: 1px solid #000;
    margin: 0 auto;
    text-align: center;
    border-radius: 5px;
}

.Message_sucess__m0Oql {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.Message_error__4bsXO {
    color: #fff;
    background-color: #fc3d50;
    border-color: #DC3545;
}

.Message_error_login__wk2kx {

    text-align: center;
    color: #DC3545;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/components/message/Message.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;;IAEI,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".message {\n    width: auto;\n    padding: 1em;\n    border: 1px solid #000;\n    margin: 0 auto;\n    text-align: center;\n    border-radius: 5px;\n}\n\n.sucess {\n    color: #155724;\n    background-color: #d4edda;\n    border-color: #c3e6cb;\n}\n\n.error {\n    color: #fff;\n    background-color: #fc3d50;\n    border-color: #DC3545;\n}\n\n.error_login {\n\n    text-align: center;\n    color: #DC3545;\n    background-color: #FFFFFF;\n    border-color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `Message_message__cjLNc`,
	"sucess": `Message_sucess__m0Oql`,
	"error": `Message_error__4bsXO`,
	"error_login": `Message_error_login__wk2kx`
};
export default ___CSS_LOADER_EXPORT___;
