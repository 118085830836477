//exception
import { ApiException } from '../../api/ApiException';

//appAxios
import appAxios from '../../api/appAxios';

//interfaces
import { IUpdateReceivedPaymentSlipRequest } from '../../interfaces/IUpdateReceivedPaymentSlipRequest';

const viewVerify = async (contractCode: string | null | undefined): Promise<IUpdateReceivedPaymentSlipRequest | ApiException> => {
  try {
    const response = await appAxios
      .get(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_RECEIVED_VERIFY}`,
        {
          params: {
            contractCode,
          },
        },
      );
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

const toUpdateReceivedPaymentSlip = async (request: IUpdateReceivedPaymentSlipRequest): Promise<void | ApiException> => {
  try {
    const response = await appAxios
      .post(`${process.env.REACT_APP_ROUTE_API__BANK_SLIP_RECEIVED_TO_UPDATE}`,
        {
          contractCode: request.contractCode,
          originCode: request.originCode,
          receivedPaymentSlip: request.receivedPaymentSlip,
        },
      );
    if (response?.status === 200) return;
    const data = response?.data || new ApiException(String(response));
    return data;

  } catch (error: any) {
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

export const PaymentSlipService = {
  toUpdateReceivedPaymentSlip,
  viewVerify,
};