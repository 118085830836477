//react
import React, { useEffect, useState } from 'react';

//css
import styles from './Login.module.css';

//components
import Input from '../../components/input/Input';

//services
import { LoginService } from '../../services/login/LoginService';

//exception
import { ApiException } from '../../api/ApiException';

// reacptcha
import ReCAPTCHA from 'react-google-recaptcha';
import { RecaptchaGoogleService } from '../../services/recaptcha_google/RecaptchaGoogleService';
import { IRecaptchaGoogleResponse } from '../../interfaces/IRecaptchaGoogleResponse';

const duration = process.env.REACT_APP_TIME_RESEND_TOKEN;
const text = process.env.REACT_APP_TIME_DURATION_TEXT_TOKEN;

type Props = {
  handleSubmitToken(e: React.FormEvent, captcha: any): void
  icon: string
  loading: boolean
  token: string
  setToken(token: string): void
  message: string,
  Message: any,
  handleBackLogin(e: React.FormEvent): void
  identifier: string
  attemps: number,
  setMessage(message: string): void
  setLoading(loading: boolean): void
  setAttemps(attemps: number): void,
  Logo: string,
  SmallLogo: string
}
const SeeTokenScreen: React.FC<Props> = (
  {
    handleSubmitToken,
    icon,
    loading,
    token,
    setToken,
    message,
    Message,
    handleBackLogin,
    identifier,
    attemps,
    setMessage,
    setLoading,
    setAttemps,
    Logo,
    SmallLogo,
  },
) => {
  const [timer, setTimer] = useState<any>('00:00');
  const [loadingResendToken, setLoadingResendToken] = useState<boolean>(false);
  const [isCount, setIsCount] = useState<boolean>(false);
  const [requiredToken, setRequiredToken] = useState<boolean>(false);
  const key_site = process.env.REACT_APP_RECAPTCHA_KEY_SITE;
  const key_secret = process.env.REACT_APP_RECAPTCHA_KEY_SECRET;
  const [captchaValue, setCaptchaValue] = useState<any>();
  let captcha: any;
  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return captcha = ref;
    }
  };
  const onChange = (value: any) => {
    setCaptchaValue(value);
    setMessage('');
  };
  const handleInvalidInput = (event: any) => {
    event.preventDefault();
    if (!event.target.value)
      event.target.classList.add('required');
    if (event.target.name === 'token') setRequiredToken(true);
  };
  const handleInputBlur = () => {
  };
  const handleResendToken = () => {
    setLoadingResendToken(true);
    if (attemps > 1 && key_site && key_secret) {
      const dados = {
        response: captchaValue,
      };
      RecaptchaGoogleService.sendTokenRecaptcha(dados).then((response) => {
        if (response instanceof ApiException) {
          captcha?.reset();
          setCaptchaValue(null);
          setLoadingResendToken(false);
        } else {
          const recaptchaGoogleResponse: IRecaptchaGoogleResponse = response;
          if (recaptchaGoogleResponse.success) {
            LoginService.resendOtp(identifier).then((response) => {
              if (response instanceof ApiException) {
                setAttemps(attemps + 1);
                captcha?.reset();
              }
              setLoadingResendToken(false);
              setCaptchaValue(null);
              startTimer();
            });
          }
        }
      });
    } else {
      LoginService.resendOtp(identifier).then((response) => {
        if (response instanceof ApiException) {

        } else {
        }
        setLoadingResendToken(false);
        startTimer();
      });
    }
  };

  function startTimer() {
    setIsCount(true);
    var timerL: any = duration;
    var minutes, seconds;

    if (timerL)
      timerL = timerL * 60;

    var timeInterval = setInterval(function() {
      minutes = parseInt(String(timerL / 60), 10);
      seconds = parseInt(String(timerL % 60), 10);
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      if (--timerL < 0) {
        setIsCount(false);
        clearInterval(timeInterval);
      }
      setTimer(`${minutes}:${seconds}`);
    }, 1000);


  }

  useEffect(() => {
    startTimer();
  }, []);
  return (
    <form onSubmit={(e: React.FormEvent) => {
      e.preventDefault();
      if (!token) {
        return;
      }
      setLoading(true);
      if (attemps > 1 && key_site && key_secret) {
        const dados = {
          response: captchaValue,
        };
        RecaptchaGoogleService.sendTokenRecaptcha(dados).then((response) => {
          if (response instanceof ApiException) {
            captcha?.reset();
            setCaptchaValue(null);
            setLoading(false);
          } else {
            const recaptchaGoogleResponse: IRecaptchaGoogleResponse = response;
            if (recaptchaGoogleResponse.success) {
              handleSubmitToken(e, captcha);
              setCaptchaValue(null);
            }
          }
        });
      } else {
        handleSubmitToken(e, null);
      }

    }}>
      <img className={styles.logoImg} src={Logo} alt="logo BBC" />
      <div className={styles.info_token}>
        <img src={icon} alt="Alerta"></img>
        <span>Verifique o token enviado para seu telefone</span>
      </div>
      <label>Token<span className={styles.asterisk}>*</span></label>
      <Input
        name="token"
        disabled={loading}
        placeholder="Digite seu token"
        value={token}
        maxLength={6}
        onChange={(e) => setToken(e.target.value)}
        required
        onInvalid={handleInvalidInput}
        onBlur={handleInputBlur}
      />
      {requiredToken && !token && <p className="inputRequired">Token obrigatório</p>}
      <div className={styles.captcha}>
        {attemps > 1 && key_site && key_secret && (
          <ReCAPTCHA
            ref={(r) => setCaptchaRef(r)}
            className={styles.captcha}
            sitekey={key_site}
            onChange={onChange}
          />
        )}
      </div>
      {message && <Message message={message} type="error_login" />}
      <div className={styles.sub_items_footer}>
        <span>Seu token expira em <span className={styles.sub_items_bold}>{text}</span></span>
      </div>
      <div className={styles.box_button}>
        <div className={styles.confirm_button}>
          {attemps > 1 && key_site && key_secret ? (
            <>
              {loading && <button disabled>Aguarde...</button>}
              {captchaValue && !loading && <button>Validar</button>}
              {!captchaValue && !loading && <button disabled>Preencha o captcha</button>}
            </>
          ) : (
            <>
              {loading && <button disabled>Aguarde...</button>}
              {!loading && <button>Validar</button>}
              <div className={`${styles.cancel_button} ${styles.box_button}`}>
                {<button type="button" onClick={handleBackLogin}>Cancelar</button>}
              </div>
              <hr className={styles.hr} />
              <p className={styles.central_message}>Em caso de duvida, você pode ligar para a central do
                banco BBC Digital
                através do número <span className={styles.central_message_span}>0800 771 4080</span></p>
            </>
          )}
        </div>
      </div>
      <div className={styles.sub_items_footer}>
        {loadingResendToken ? (
          <span className={styles.sub_items}>Aguarde...</span>
        ) : (
          <>
            {attemps > 1 && key_site && key_secret ? (
              <>
                {loading && <button disabled>Aguarde...</button>}
                {captchaValue && !loading && (
                  <>
                    {!isCount ? (
                      <span className={styles.sub_items} onClick={handleResendToken}> Reenviar token</span>
                    ) : <p>{timer}</p>}
                  </>
                )}
                {!captchaValue && !loading && (
                  <>
                    {isCount ? (
                      <p>{timer}</p>
                    ) : (
                      <button disabled>Preencha o captcha</button>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {!isCount ? (
                  <span className={styles.sub_items}
                        onClick={handleResendToken}> Reenviar token</span>
                ) : <p>{timer}</p>}
              </>
            )}

          </>
        )}
      </div>
    </form>
  );
};

export default SeeTokenScreen;