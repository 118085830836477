//exception
import { ApiException } from '../../api/ApiException';

//appAxios
import appAxios from '../../api/appAxios';
import { IRecaptchaGoogleResponse } from '../../interfaces/IRecaptchaGoogleResponse';

const sendTokenRecaptcha = async (response: any): Promise<IRecaptchaGoogleResponse | ApiException> => {
  try {
    const res = await appAxios.post(`${process.env.REACT_APP_RECAPTCHA_SEND_TOKEN_VERIFY}`, null,
      {
        params: response,
      });
    const data = res?.data || new ApiException(String(res));
    return data;

  } catch (error: any) {
    console.log(error);
    return new ApiException(error?.response?.data?.message || error?.message);
  }
};

export const RecaptchaGoogleService = {
  sendTokenRecaptcha,
};