import React from 'react';


//css
import styles from './ContractCards.module.css';

// features
import ContractCard from '../contractCard/ContractCard';

//interfaces
import { IContract } from '../../../interfaces/IContract';

type Props = {
  contracts: IContract[] | []
}
const ContractCards: React.FC<Props> = ({ contracts = [] }) => {
  return (
    <div className={styles.contracts}>
      {contracts.map((contract, index) => (
        <ContractCard
          key={index}
          contract={contract}
        />
      ))}
    </div>
  );
};

export default ContractCards;