//react
import React, { useState } from 'react';

//css
import styles from './Login.module.css';

//components
import Input from '../../components/input/Input';

// reacptcha
import ReCAPTCHA from 'react-google-recaptcha';
import { ApiException } from '../../api/ApiException';
import { RecaptchaGoogleService } from '../../services/recaptcha_google/RecaptchaGoogleService';
import { IRecaptchaGoogleResponse } from '../../interfaces/IRecaptchaGoogleResponse';

type Props = {
  handleSubmitFisrtAccess(e: React.FormEvent, captcha: any): void
  loading: boolean
  cpfCnpj: string
  setCpfCnpj(cpfCnpj: string): void
  Formatters: any
  phone: string
  setPhone(phone: string): void
  message: string
  Message: any
  handleBackLogin(e: React.FormEvent): void
  handlePresentationScreen(e: React.FormEvent): void
  attemps: number,
  setMessage(message: string): void
  setLoading(loading: boolean): void,
  Logo: string,
  SmallLogo: string
}
const SeeFirstAccessScreen: React.FC<Props> = (
  {
    handleSubmitFisrtAccess,
    loading,
    cpfCnpj,
    setCpfCnpj,
    Formatters,
    phone,
    setPhone,
    message,
    Message,
    handleBackLogin,
    attemps,
    setMessage,
    setLoading,
    Logo,
    SmallLogo,
    handlePresentationScreen,
  },
) => {
  const [requiredUsername, setRequiredUsername] = useState<boolean>(false);
  const [requiredPhone, setRequiredPhone] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<any>();
  const key_site = process.env.REACT_APP_RECAPTCHA_KEY_SITE;
  const key_secret = process.env.REACT_APP_RECAPTCHA_KEY_SECRET;
  let captcha: any;
  const handleInvalidInput = (event: any) => {
    event.preventDefault();

    event.target.classList.add('required');

    if (event.target.name === 'username') setRequiredUsername(true);
    if (event.target.name === 'phone') setRequiredPhone(true);
  };
  const handleInputBlur = () => {
  };
  const setCaptchaRef = (ref: any) => {
    if (ref) {
      return captcha = ref;
    }
  };
  const onChange = (value: any) => {
    setCaptchaValue(value);
    setMessage('');
  };
  return (
    <form onSubmit={(e: React.FormEvent) => {
      e.preventDefault();
      if (!phone || !cpfCnpj) {
        return;
      }
      setLoading(true);
      if (attemps > 1 && key_site && key_secret) {
        const dados = {
          response: captchaValue,
        };
        RecaptchaGoogleService.sendTokenRecaptcha(dados).then((response) => {
          if (response instanceof ApiException) {
            captcha?.reset();
            setCaptchaValue(null);
            setLoading(false);
          } else {
            const recaptchaGoogleResponse: IRecaptchaGoogleResponse = response;
            if (recaptchaGoogleResponse.success) {
              handleSubmitFisrtAccess(e, captcha);
              setCaptchaValue(null);
            }
          }
        });
      } else {
        handleSubmitFisrtAccess(e, null);
      }
    }}>
      <img className={styles.logoImg} src={Logo} alt="logo BBC" />
      <div className={styles.subtitle}>
        <span>Primeiro Acesso</span>
      </div>
      <label>CPF/CNPJ <span className={styles.asterisk}>*</span></label>
      <Input
        name="username"
        disabled={loading}
        placeholder="Digite seu CPF/CNPJ"
        value={cpfCnpj}
        onChange={(e) =>
          setCpfCnpj(Formatters.formatterCpfCnpj(e.target.value))
        }
        required
        onInvalid={handleInvalidInput}
        onBlur={handleInputBlur}
      />
      {requiredUsername && !cpfCnpj && <p className="inputRequired">CPF/CNPJ obrigatório</p>}
      <label>Telefone <span className={styles.asterisk}>*</span></label>
      <Input
        name="phone"
        disabled={loading}
        placeholder="Digite seu telefone cadastrado"
        value={phone}
        maxLength={15}
        onChange={(e) => setPhone(Formatters.phoneMaskSimple(e.target.value))}
        required
        onInvalid={handleInvalidInput}
        onBlur={handleInputBlur}
      />
      {requiredPhone && !phone && <p className="inputRequired">Telefone obrigatório</p>}
      <div className={styles.captcha}>
        {attemps > 1 && key_site && key_secret && (
          <ReCAPTCHA
            ref={(r) => setCaptchaRef(r)}
            className={styles.captcha}
            sitekey={key_site}
            onChange={onChange}
          />
        )}
      </div>
      {message && <Message message={message} type="error_login" />}
      <div className={styles.box_button}>
        <div className={styles.confirm_button}>
          {attemps > 1 && key_site && key_secret ? (
            <>
              {loading && <button disabled>Aguarde...</button>}
              {captchaValue && !loading && <button>Continuar</button>}
              {!captchaValue && !loading && <button disabled>Preencha o captcha</button>}
            </>
          ) : (
            <>
              {loading && <button disabled>Aguarde...</button>}
              {!loading && <button>Continuar</button>}
              <div className={`${styles.cancel_button} ${styles.box_button}`}>
                <button type="button" onClick={handlePresentationScreen}>Cancelar</button>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default SeeFirstAccessScreen;